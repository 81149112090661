@import url("https://swc.autodesk.com/pharmacopeia/fonts/dhig2021.css");

* {
  font-family: "ArtifaktElement", "Segoe UI", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: ArtifaktLegend;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: unset;
  margin-bottom: unset;
}

h2,
h3,
h4,
h5,
h6 {
  margin: unset;
}
