.App-password {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  h3 {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    vertical-align: bottom;
  }

  .Change-user {
    display: inline-flex;
  }

  .App-second-cta {
    label {
      margin-left: 8px;
    }
  }

  div {
    &.App-password-input {
      position: relative;
      a {
        &.forgot {
          font-size: 12px;
          position: absolute;
          right: 0.5em;
          bottom: 1em;
          text-align: right;
          text-decoration: underline;
        }
      }
    }
  }
}
