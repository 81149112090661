.App-error {
  box-shadow: unset;

  &.Dark {
    box-shadow: unset;
  }

  .App-header {
    padding-bottom: 64px;
    .App-img {
      height: 20px;
    }
  }
  .App-body {
    padding: 0 48px 48px;

    img {
      width: 64px;
      height: 64px;
    }

    h1 {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .App-error {
    margin: auto;

    .App-header {
      padding: 32px 16px;
    }
    .App-body {
      padding: 32px 16px 48px;
    }
  }
}
