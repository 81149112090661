@import "./sass/background";
@import "./sass/typography";

.App {
  min-width: 320px;
  width: 100vw;
  max-width: 560px;
  /* light/fill-elevation-1 */
  background: #fff;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1) inset;
  border-radius: 8px;
  margin-top: 128px;
  margin-left: auto;
  margin-right: auto;

  &.Dark {
    background: rgba(38, 38, 38, 1);
    box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.4) inset;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2) inset;

    .App-header {
      // border-bottom: 1px solid rgba(196, 196, 196, 1);

      .App-img {
        background-image: url("https://damassets.autodesk.net/content/dam/autodesk/logos/autodesk-logo-wht.svg");
      }
    }
    .App-divider {
      // display: none;
    }
    .App-body {
      h1 {
        color: #fff;
      }
      h3,
      p {
        color: #fff !important;
      }
      form {
        label {
          color: #fff;
        }

        input {
          color: #fff;
          background: rgba(255, 255, 255, 0.05);
          box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.25),
            inset 0px -1px 0px #fff;
        }
      }
      .App-second-cta {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
  }

  .App-header {
    padding: 32px 48px;

    .App-img {
      background-image: url("https://damassets.autodesk.net/content/dam/autodesk/logos/autodesk-logo-blk.svg");
      height: 17px;
      background-repeat: no-repeat;
    }
  }

  .offsetOpacity {
    opacity: 0;
  }

  .fadeOutLeft {
    transform: translate(-10%, 0%) !important;
    opacity: 0;
  }

  .fadeOutRight {
    transform: translate(10%, 0%) !important;
    opacity: 0;
  }

  .App-body {
    padding: 32px 48px 48px;

    h1 {
      display: inline-block;
      vertical-align: middle;
      color: #000;
    }
    h3 {
      color: #000;
    }
    form {
      label {
        display: block;
        font-family: ArtifaktElement;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #000;
        margin-bottom: 4px;
      }
      input {
        border: unset;
        border-radius: unset;
        padding: unset;
        width: 100%;
        display: block;
        padding: 0px 8px;
        width: calc(100% - 16px);
        height: 40px;
        background: #fff;
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3),
          inset 0px -1px 0px #000;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid rgba(128, 128, 255, 1);
        }
        :focus {
          border: 2px solid rgba(95, 96, 255, 1);
        }
      }
    }

    .App-second-cta {
      display: inline-flex;
      font-family: ArtifaktElement;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #212121;
    }
  }
}

@media only screen and (max-width: 680px) {
  .App {
    max-width: 512px;
    margin: auto;
    box-shadow: unset;
    border-radius: unset;

    margin-top: 48px;

    &.Dark {
      box-shadow: unset;
    }

    .App-header {
      padding: 32px 24px;
    }
    .App-divider {
      display: unset;
    }
    .App-body {
      padding: 8px 24px 48px;
    }
  }
}
