.App-forget {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  .Change-user {
    display: inline-flex;
  }

  span {
    vertical-align: bottom;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
  }
}
