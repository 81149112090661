body {
  display: flow-root;
  background-image: none;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-color: #fff;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.Dark {
    background-color: rgba(38, 38, 38, 1);
  }
}

@media only screen and (min-width: 679px) {
  body {
    background-image: url("../img/background.png");
    background-color: #000;
    // display: flex;
  }
}
