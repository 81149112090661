@import "@digital-hig/theme-data/lib/build/css/dhig-global.css";
.App-loading {
  transition: opacity 0.3s ease-in-out;
  display: inline-flex;
  vertical-align: middle;
  img {
    height: 64px;

    &.rotating {
      animation: rotating 2s linear infinite;
    }
  }
}
@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}
