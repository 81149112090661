.App-landing {
  h3 {
    font-size: 20px;

    span {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  div {
    &.progress {
      display: inline-flex;
    }
  }

  p {
    &.email {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: rgba(0, 0, 0, 0.6);
    }
    &.progress {
      font-size: 14px;
    }
  }

  img {
    &.rotating {
      animation: rotating 2s linear infinite;
    }
  }

  .App-second-cta {
    display: block !important;
  }
}

@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}
